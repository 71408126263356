.home_bg{
    display:flex;
    flex-direction: row;
    flex-flow: row;
    justify-content: flex-start;
    width:100vw;
    height: 100vh;
    align-items: center;
    background-image: url("./assets/honeyback.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0%;
    padding: 0%;
    border: 0;
    z-index: -1;
    overflow: hidden;
}
*{
    margin: 0;
}

.details-button{
    width: 50%;
    height: 100vh;
    margin: 2vw;
    padding: 0%;
}

.perso-details-button{
    background-color: #255784;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: fixed;
    display: inline-block;
    margin-top:5%;
    padding: 15px 30px;
    color: #2196f3;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-decoration: none;
    overflow:hidden;
    transition: 0.2s;
    border: #2196f3 solid 2px;
    border-radius: 50px 50px; 
}
.perso-details-button:active, .perso-details-button:visited{
    color:#255784;
    background-color: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
}
.perso-details-button:hover{
    color:#255784;
    background-color: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
    
}


.edu-details-button{
    background-color: #255784;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: fixed;
    display: inline-block;
    margin-top:15%;
    padding: 15px 30px;
    color: #2196f3;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-decoration: none;
    overflow:hidden;
    transition: 0.2s; 
    border: #2196f3 solid 2px;
    border-radius: 50px 50px; 
}
.edu-details-button:hover{
    color:#255784;
    background-color: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
    
}

.exp-details-button{
    background-color: #255784;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: fixed;
    display: inline-block;
    margin-top:25%;
    padding: 15px 30px;
    color: #2196f3;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-decoration: none;
    overflow:hidden;
    transition: 0.2s; 
    border: #2196f3 solid 2px;
    border-radius: 50px 50px; 
}
.exp-details-button:hover{
    color:#255784;
    background-color: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
    
}

.proj-details-button{
    background-color: #255784;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: fixed;
    display: inline-block;
    margin-top:35%;
    padding: 15px 30px;
    color: #2196f3;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-decoration: none;
    overflow:hidden;
    transition: 0.2s; 
    border: #2196f3 solid 2px;
    border-radius: 50px 50px; 
}
.proj-details-button:hover{
    color:#255784;
    background-color: #2196f3;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
    
}


.glass-info-box{
    align-items: flex-end;
    margin-right: 10vw;
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 70vw;
    background: linear-gradient(135deg, rbga(255, 248, 248, 0.1), rbga(255, 248, 248, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid #2196f3;
    color: #2196f3;
}
.glass-info-box h1{
  padding: 2vw;
  padding-right: 5vw;
  font-size:xxx-large;
  font-family: cursive;
}
.glass-info-box:hover{
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
}

.mygradimage{
    display: flex;
    height: 40vh;
    width: 200px;
    background-image: url("./assets/mygradimage.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    border-radius: 0px 0px 20px 00px;
    align-items: flex-end;
    

}




.glass-perso-box{
        align-items: flex-start;
        margin-right: 10vw;
        display: flex;
        flex-direction: column;
        height: 70vh;
        width: 70vw;
        background: linear-gradient(135deg, rbga(255, 248, 248, 0.1), rbga(255, 248, 248, 0));
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 20px;
        border: 1px solid #2196f3;
        color: #2196f3;
        padding: 10px;
}
.glass-perso-box:hover{
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
}